$.removeSticky = function () {
    setTimeout(function () {
        $("#layout-navigation").removeClass("sticky");
    }, 100);
};

$(document).ready(function () {

    /* jQuery scroll to top plugin start */
    $.topbutton({
        htlm: "↑",//String
        css: "", //String
        scrollAndShow: false, //Boolean
        scrollSpeed: 150, //Number
        scrollAndShow: true,
    });
    /* jQuery scrollToTop plugin end */

    window.onscroll = function () {
        stickyNavbar()
    };
    window.onload = function () {
        stickyNavbar()
    };

    var navbar = document.querySelector("#layout-navigation nav"); // Get the navbar
    var sticky = navbar.offsetTop; // Get the offset position of the navbar

    function stickyNavbar() {
        if (window.pageYOffset > sticky) {
            document.querySelector("#layout-navigation").classList.add("sticky");
            document.querySelector(".zone.zone-navigation").classList.add("bg-light");
        } else {
            document.querySelector(".zone.zone-navigation").classList.remove("bg-light");
            document.querySelector("#layout-navigation").classList.remove("sticky");
        }
    }

    /* Contact US Submitting form ( Do you have questions about ConnectorSolutions? )*/
    function toggleCheck() {
        $("#QuestionFormSubmit").prop('disabled', function (i, v) {
            return !v;
        });
    };
    $('#QuestionFormSubmitCheckbox').change(function () {
        toggleCheck();
    });

    /* Used on "How it works" page with pill tab */
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        $(e.target.parentNode.parentNode).find("li").removeClass("active");
        $(e.target.parentNode).addClass("active");
    })

    $('a[data-toggle="tab"]').on('hide.bs.tab', function (e) {
        $(e.target.parentNode).removeClass("active");
    })

    if ($(window).scrollTop() >= 1)  // Проверка состояния прокрутки
    {
        $("html, body").animate({scrollTop: 0}, "slow");
    }

    $('.js-mobile-menu').on('click', function (event) {
        event.preventDefault();
        $('body').toggleClass('overflow-hidden');
    });

    const btnHeader = document.querySelectorAll('a.btn-header');

    btnHeader.forEach(function(element) {
        element.addEventListener('click', function(event) {
            event.preventDefault(); // отменяем стандартное поведение ссылки
            const currentURL = encodeURIComponent(window.location.href);
            const targetURL = currentURL.includes('connector.solutions') ? 'https://my.connector.solutions/' : 'https://connector-front.ffsw.dev/'
            const fromSiteParam = 'fromSite=' + window.ff.baseURL; // params fromSite
            const langCodeParam = 'lang=' + window.ff.localCode?.toLowerCase(); // params lang
            const href = 'public/auth/sign-in?'; // url with lang params
            const url = targetURL + href + langCodeParam + '&' + fromSiteParam;
            window.open(url, '_self');
        });
    });
});