document.addEventListener("DOMContentLoaded", (event) => {
    const redirectButton = document.getElementById('redirectButton');
    if(redirectButton != null){
        redirectButton.addEventListener('click', redirectToSite);
    }else{
        console.log('Element with id redirectButton not found.');
    }
});


window.addEventListener('scroll', () => {
    const scroll = window.scrollY;
    const articles = document.querySelectorAll('.main__heading');
    const menuLinks = document.querySelectorAll('.sidebar__menu a');
    const lastArticleIndex = articles.length - 1;

    articles.forEach((el, i) => {
        if (el.offsetTop - document.querySelector('.navbar').clientHeight - 100 <= scroll) {
            menuLinks.forEach((link) => {
                if (link.classList.contains('active')) {
                    link.classList.remove('active');
                }
            });
            document.querySelectorAll('.sidebar__menu li')[i].querySelector('a').classList.add('active');
        }
    });

    // Add class '.active' for last elem if we got end of page
    const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
    if (scroll === pageHeight) {
        menuLinks.forEach((link) => {
            if (link.classList.contains('active')) {
                link.classList.remove('active');
            }
        });
        document.querySelectorAll('.sidebar__menu li')[lastArticleIndex].querySelector('a').classList.add('active');
    }
});
function redirectToSite() {
    if (/iPhone|iPad|Macintosh/i.test(navigator.userAgent)) {
        window.location.href = 'https://apps.apple.com/gb/app/ironwallet-sell-buy-crypto/id6451146325';
    } else if (/Android/i.test(navigator.userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.wallet.crypto.btc.eth&pli=1';
    } else {
        window.location.href = 'https://ironwallet.io/';
    }
}




